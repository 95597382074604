import React, { useEffect, useState, useCallback, useContext } from 'react';
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { Input, FormGroup, InputGroupAddon, InputGroup, InputGroupText, Label, Button } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import { MAP } from 'react-google-maps/lib/constants';
import { AppStateContext } from '../AppStateProvider';
const google = window.google;

const RoadSegmentEditorMap = withGoogleMap(({ points, milestones, onDragPoint, onCreateMidPoint }) => {
    const handleMapMounted = useCallback((ref) => {
        if (!ref) return;
        const map = ref.context[MAP];

        const mapTypeIds = [];
        for (var type in google.maps.MapTypeId) {
            mapTypeIds.push(google.maps.MapTypeId[type]);
        }
        mapTypeIds.push("OSM");

        map.mapTypes.set("OSM", new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                // See above example if you need smooth wrapping at 180th meridian
                return `https://tile.openstreetmap.org/${zoom}/${coord.x}/${coord.y}.png`;
            },
            tileSize: new google.maps.Size(256, 256),
            name: "OpenStreetMap",
            maxZoom: 19
        }));

        map.setOptions({
            mapTypeId: "OSM",
            mapTypeControlOptions: {
                mapTypeIds: mapTypeIds
            }
        });
    }, []);
    return (
        <GoogleMap
            ref={handleMapMounted}
            defaultCenter={{ lat: 41.6956, lng: -8.736245 }}
            defaultZoom={5}>
            {points.map((p, index) => (
                <Marker
                    key={index}
                    position={p.point}
                    label={p.km.toString()}
                    draggable={true}
                    onDragEnd={(e) => onDragPoint(e, index)} />
                ))}
            {!milestones.loading && milestones.data && (
                milestones.data.map(m => (
                    <Marker
                        key={m.km}
                        position={m.point}
                        icon="dot-and-circle.svg"
                        title={m.km.toString()}
                        draggable={true}
                        onDragEnd={(e) => onCreateMidPoint(e, m.km)}/>
                ))
            )}
        </GoogleMap>
    );
});

const defaultProps = {
    roadSegment: {
        roadName: "",
        points: [
            {
                point: { lat: 41.58956, lng: -8.726245 },
                km: 0
            },
            {
                point: { lat: 41.78956, lng: -8.756245 },
                km: 30
            }
        ]
    }
};

async function generateMilestones(points) {
    const res = await fetch("milestones/generate", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify(points)
    });
    return res.status === 200 ? await res.json() : [];
}

export default function RoadSegmentEditor(props = defaultProps) {
    const { saveRoadSegment } = useContext(AppStateContext);
    const history = useHistory();
    const [roadSegment, setRoadSegment] = useState(props.roadSegment || defaultProps.roadSegment);
    const [milestones, setMilestones] = useState({ loading: true, data: [] });

    const googleLatLngToPoint = (latLng) => ({ lat: latLng.lat(), lng: latLng.lng() });

    const updatePoints = (points) => setRoadSegment({ ...roadSegment, points });

    const onDragPoint = (e, index) => {
        var updatedPoint = {
            ...roadSegment.points[index],
            point: googleLatLngToPoint(e.latLng)
        };
        var newPoints = roadSegment.points.map((p, i) => i === index ? updatedPoint : p)
        updatePoints(newPoints);
    }

    const onCreateMidPoint = (e, km) => {
        var pointToInsert = {
            point: googleLatLngToPoint(e.latLng),
            km
        };
        var before = roadSegment.points.filter(p => p.km < km);
        var after = roadSegment.points.filter(p => p.km > km);
        updatePoints([...before, pointToInsert, ...after]);
    }

    const updateKm = (pointIndex, newValue) => {
        var newPoints = roadSegment.points.map((p, i) => i === pointIndex ? { ...p, km: parseInt(newValue) } : p);
        updatePoints(newPoints);
    }

    const deletePoint = (pointIndex) => {
        var newPoints = roadSegment.points.filter((_, i) => i !== pointIndex);
        updatePoints(newPoints);
    }

    const updateRoadname = (e) => {
        setRoadSegment({ ...roadSegment, roadName: e.target.value });
    }

    const onSave = async () => {
        await saveRoadSegment(roadSegment);
        history.push("/");
    }

    useEffect(() => {
        const loadSegment = async () => {
            setMilestones({ loading: true, data: [] });
            setMilestones({ loading: false, data: await generateMilestones(roadSegment.points) });
        }
        loadSegment();
    }, [roadSegment.points]);

    return (
        <div style={{ flex: 1, position: "relative", display: "flex" }}>
            <div style={{ padding: 30, width: 350 }}>
                <FormGroup>
                    <Label for="roadName">Road Name</Label>
                    <Input type="text" name="roadName" value={roadSegment.roadName} onChange={updateRoadname} />
                </FormGroup>
                <FormGroup tag="fieldset">
                    <legend>Points</legend>
                    {roadSegment.points.map((p, idx) => (
                        <FormGroup key={idx}>
                            <InputGroup>
                                {idx > 0 && idx < roadSegment.points.length - 1 && (
                                    <InputGroupAddon addonType="prepend">
                                        <Button color="danger" onClick={() => deletePoint(idx)}>x</Button>
                                    </InputGroupAddon>
                                )}
                                <Input type="number" value={p.km} onChange={e => updateKm(idx, e.target.value)} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>km</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    ))}
                </FormGroup>
                <Button color="primary" onClick={onSave}>Save</Button>{' '}
                <Button color="secondary" tag={Link} to="/">Cancel</Button>
            </div>
            <div style={{ position: "relative", flex: 1 }}>
                <RoadSegmentEditorMap
                    points={roadSegment.points}
                    milestones={milestones}
                    onDragPoint={onDragPoint}
                    onCreateMidPoint={onCreateMidPoint}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />} />
            </div>
        </div>
    );
}