import React, { useContext, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AppStateContext } from '../AppStateProvider';

export function Home() {
    const { state, fetchRoadSegments, deleteRoadSegment } = useContext(AppStateContext);
    const isLoading = state.roadSegments === null || state.loading;

    useEffect(() => {
        if (state.roadSegments === null) fetchRoadSegments();
    }, [state.roadSegments, fetchRoadSegments])

    const handleDeleteSegment = (id) => {
        if (window.confirm("Delete this Road Segment?")) {
            deleteRoadSegment(id);
        }
    }

    const renderSementsTable = (segments) => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Road Name</th>
                        <th>Start</th>
                        <th>End</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {segments.map(s =>
                        <tr key={s.id}>
                            <td>{s.roadName}</td>
                            <td>{s.points[0].km} km</td>
                            <td>{s.points.slice(-1)[0].km} km</td>
                            <td>
                                <Button tag={Link} color="primary" to={`edit/${s.id}`}>
                                    Edit
                                </Button>
                                {" "}
                                <Button color="danger" onClick={() => handleDeleteSegment(s.id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    const contents = isLoading
        ? <p><em>Loading...</em></p>
        : renderSementsTable(state.roadSegments);

    return (
        <div style={{ padding: 30, flex: 1, minHeight: 0, overflow: "auto" }}>
            <h2>Road Segments</h2>
            {contents}
        </div>
    );
}
