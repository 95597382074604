import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
                <NavMenu />
                <div style={{ position: "relative", flex: 1, minHeight: 0, display: "flex" }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
