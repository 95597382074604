import React, { useReducer, useCallback, useEffect } from 'react';

const tokenCookieName = "jwttoken";

function setJwtTokenCookie(token) {
    document.cookie = `${tokenCookieName}=${token}`;
}

function getJwtTokenCookie() {
    const cookies = Object.fromEntries(
        document.cookie.split(/\s*;\s*/).map(e => e.split(/\s*=\s*/))
    );
    return cookies[tokenCookieName];
}

export function deleteJwtTokenCookie() {
    document.cookie = `${tokenCookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const initialState = {
    authenticated: false,
    loginFailed: false,
    loading: false,
    jwtToken: null,
    roadSegments: null
}

export const AppStateContext = React.createContext({
    state: initialState,
    login: ({ username, password }) => { },
    logout: () => { },
    fetchRoadSegments: () => { },
    saveRoadSegment: (roadSegment) => { },
    deleteRoadSegment: (id) => { },
});


function updateRoadSegment(roadSegments, updatedSegment) {
    return roadSegments.filter(s => s.id !== updatedSegment.id).concat([updatedSegment]);
}

function deleteRoadSegment(roadSegments, id) {
    return roadSegments.filter(s => s.id !== id);
}

function reducer(state, action) {
    switch (action.type) {
        case 'login':
            return { ...state, authenticated: true, loginFailed: false, jwtToken: action.payload.jwtToken };
        case 'login_failed':
            return { ...state, loginFailed: true };
        case 'logout':
            return { ...state, authenticated: false, jwtToken: null };
        case 'fetch_road_segments_start':
        case 'save_road_segment_start':
        case 'delete_road_segment_start':
            return { ...state, loading: true };
        case 'fetch_road_segments_done':
            return { ...state, loading: false, roadSegments: action.payload };
        case 'save_road_segment_done':
            return { ...state, loading: false, roadSegments: updateRoadSegment(state.roadSegments, action.payload) };
        case 'delete_road_segment_done':
            return { ...state, loading: false, roadSegments: deleteRoadSegment(state.roadSegments, action.payload) };
        default:
            throw new Error();
    }
}

export default function AppStateProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchDataAuth = useCallback(async (url, { method, data } = { method: "GET" }) => {
        const headers = { "Authorization": `Bearer ${state.jwtToken}` };

        if (data) headers["Content-Type"] = "application/json; charset=utf-8";

        const res = await fetch(url, {
            method,
            headers,
            body: data && JSON.stringify(data)
        });

        return res;
    }, [state.jwtToken]);

    const logout = useCallback(() => {
        dispatch({ type: "logout" });
        deleteJwtTokenCookie();
    }, [dispatch]);

    const login = useCallback(async ({ username, password }) => {
        const res = await fetch("auth", {
            method: "POST",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({ username, password })
        });
        if (res.status === 200) {
            const jwtToken = (await res.json()).token;
            setJwtTokenCookie(jwtToken)
            dispatch({ type: "login", payload: { jwtToken } });
        } else {
            dispatch({ type: "login_failed" });
        }
    }, [dispatch]);

    const fetchRoadSegments = useCallback(async () => {
        dispatch({ type: "fetch_road_segments_start" });
        const res = await fetchDataAuth('roadsegments');
        if (res.status === 200)
            dispatch({ type: "fetch_road_segments_done", payload: await res.json() });
        else
            logout();
    }, [dispatch, logout, fetchDataAuth]);

    const saveRoadSegment = useCallback(async (roadSegment) => {
        dispatch({ type: "save_road_segment_start" });
        const url = roadSegment.id ? `roadsegments/${roadSegment.id}` : 'roadsegments';
        const method = roadSegment.id ? "PUT" : "POST";
        const res = await fetchDataAuth(url, { method, data: roadSegment });
        if (res.status === 200)
            dispatch({ type: "save_road_segment_done", payload: await res.json() });
        else
            logout();
    }, [dispatch, logout, fetchDataAuth]);

    const deleteRoadSegment = useCallback(async (id) => {
        dispatch({ type: "delete_road_segment_start" });
        const res = await fetchDataAuth(`roadsegments/${id}`, { method: "DELETE" });
        if (res.status === 200)
            dispatch({ type: "delete_road_segment_done", payload: id });
        else
            logout();
    }, [dispatch, logout, fetchDataAuth]);

    useEffect(() => {
        const jwtToken = getJwtTokenCookie();
        if (jwtToken) dispatch({ type: "login", payload: { jwtToken } })
    }, [dispatch]);

    return (
        <AppStateContext.Provider
            value={{ state, login, logout, fetchRoadSegments, saveRoadSegment, deleteRoadSegment }}>
            {children}
        </AppStateContext.Provider>
    );
}