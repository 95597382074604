import React, { Component, useEffect, useContext, useMemo } from 'react';
import { Route, useParams } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import RoadSegmentEditor from './components/RoadSegmentEditor';

import './custom.css'
import { AppStateContext } from './AppStateProvider';
import { Login } from './components/Login';

function EditRoadSegment() {
    const { state, fetchRoadSegments } = useContext(AppStateContext);
    const { id } = useParams();
    const roadSegment = useMemo(() => (state.roadSegments || []).find(s => s.id === id), [state.roadSegments, id]);

    useEffect(() => {
        if (state.roadSegments === null) fetchRoadSegments();
    }, [state.roadSegments, fetchRoadSegments]);

    return roadSegment == null || state.loading
        ? <div style={{padding: 30}}><em>Loading...</em></div>
        : <RoadSegmentEditor roadSegment={roadSegment} />;
}

export default function App() {
    const { state } = useContext(AppStateContext);

    if (state.authenticated) {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/new' component={RoadSegmentEditor} />
                <Route path='/edit/:id' component={EditRoadSegment} />
            </Layout>
        );
    } else {
        return <Login />;
    }
}
