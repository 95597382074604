import React, { useContext, useState } from "react";
import { Container, Input, Label, Button, Card, CardBody, CardTitle, FormGroup, CardText, Alert } from "reactstrap";
import { AppStateContext } from "../AppStateProvider";

export function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { state, login } = useContext(AppStateContext);
    return (
        <Container
            fluid={true}
            className="text-center"
            style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", background: "#456" }}>
            <div style={{ minWidth: 300, maxWidth: 400, flex: 1 }}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h3>Login</h3>
                        </CardTitle>
                        <FormGroup>
                            <Label for="inputEmail" className="sr-only">Username</Label>
                            <Input
                                type="text"
                                id="inputusername"
                                placeholder="Username"
                                required
                                autoFocus
                                value={username}
                                onChange={e => setUsername(e.target.value)} />
                            <Label for="inputPassword" className="sr-only">Password</Label>
                            <Input
                                type="password"
                                id="inputPassword"
                                placeholder="Password"
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}/>
                        </FormGroup>
                        {state.loginFailed && (
                            <Alert color="danger">
                                Login Failed
                            </Alert>)}
                        <Button
                            color="primary"
                            type="submit"
                            onClick={() => login({ username, password })}>
                            Sign in
                        </Button>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
}